<template>
  <table class="table is-bordered my-table">
    <tr class="table-header">
      <td colspan="3">Payment</td>
    </tr>      
    <tr>
      <td>Basic Salary</td>
      <td>{{ toCurrency(thisSalary) }}</td>
      <td></td>
    </tr>
    <tr>
      <td>Holidays</td>
      <td>{{ toCurrency(totalHoliday) }}</td>
      <td></td>
    </tr>      
    <tr>
      <td>Overtime</td>
      <td>{{ toCurrency(totalOvertime) }}</td>
      <td></td>
    </tr>    
    <tr>
      <td>Night Diff</td>
      <td>{{ toCurrency(nightDiff) }}</td>
      <td></td>
    </tr>    
    <tr>
      <td>Gross Pay</td>
      <td>{{ toCurrency(totalGrossPay) }}</td>
      <td></td>
    </tr>
    <tr>
      <td>Total Taxable Gross</td>
      <td>{{ toCurrency(totalTaxableGross) }}</td>
      <td></td>
    </tr>
    <tr class="table-header">
      <td colspan="3">Deductions</td>
    </tr>        
    <tr>
      <td>Witholding Tax</td>
      <td></td>
      <td>({{ toCurrency(withholdingTax) }})</td>
    </tr>
    <tr>
      <td>SSS Contribution</td>
      <td></td>
      <td>({{ toCurrency(sssContribution) }})</td>
    </tr>
    <tr>
      <td>Philhealth Contribution</td>
      <td></td>
      <td>({{ toCurrency(phContribution) }})</td>
    </tr>  
    <tr>
      <td>PAG-IBIG Contribution</td>
      <td></td>
      <td>({{ toCurrency(pagIbigContribution) }})</td>
    </tr>
    <tr class="table-header">
      <td colspan="3">Netpay</td>
    </tr>
    <tr class="table-header" ref="net-pay">
      <td class="net-pay" style="text-align: center;" colspan="3">{{ toCurrency(netPay) }}</td>
    </tr>         
  </table>
</template>

<script>


const SSS_TABLE = [
  { from: 0, to: 4249.99, value: 180 },
  { from: 4250.00, to: 4749.99, value: 202.50, valueWisp: 0 },
  { from: 4750.00, to: 5249.99, value: 225.00, valueWisp: 0 },
  { from: 5250.00, to: 5749.99, value: 247.50, valueWisp: 0 },
  { from: 5750.00, to: 6249.99, value: 270.00, valueWisp: 0 },
  { from: 6250.00, to: 6749.99, value: 292.50, valueWisp: 0 },
  { from: 6750.00, to: 7249.99, value: 315.00, valueWisp: 0 },
  { from: 7250.00, to: 7749.99, value: 337.50, valueWisp: 0 },
  { from: 7750.00, to: 8249.99, value: 360.00, valueWisp: 0 },
  { from: 8250.00, to: 8749.99, value: 382.50, valueWisp: 0 },
  { from: 8750.00, to: 9249.99, value: 405.00, valueWisp: 0 },
  { from: 9250.00, to: 9749.99, value: 427.50, valueWisp: 0 },
  { from: 9750.00, to: 10249.99, value: 450.00, valueWisp: 0 },
  { from: 10250.00, to: 10749.99, value: 472.5, valueWisp: 0 },
  { from: 10750.00, to: 11249.99, value: 495.00, valueWisp: 0 },
  { from: 11250.00, to: 11749.99, value: 517.50, valueWisp: 0 },
  { from: 11750.00, to: 12249.99, value: 540.00, valueWisp: 0 },
  { from: 12250.00, to: 12749.99, value: 562.50, valueWisp: 0 },
  { from: 12750.00, to: 13249.99, value: 585.00, valueWisp: 0 },
  { from: 13250.00, to: 13749.99, value: 607.50, valueWisp: 0 },
  { from: 13750.00, to: 14249.99, value: 630.00, valueWisp: 0 },
  { from: 14250.00, to: 14749.99, value: 652.50, valueWisp: 0 },
  { from: 14750.00, to: 15249.99, value: 675.00, valueWisp: 0 },
  { from: 15250.00, to: 15749.99, value: 697.50, valueWisp: 0 },
  { from: 15750.00, to: 16249.99, value: 720.00, valueWisp: 0 },
  { from: 16250.00, to: 16749.99, value: 742.50, valueWisp: 0 },
  { from: 16750.00, to: 17249.99, value: 765.00, valueWisp: 0 },
  { from: 17250.00, to: 17749.99, value: 787.50, valueWisp: 0 },
  { from: 17750.00, to: 18249.99, value: 810.00, valueWisp: 0 },
  { from: 18250.00, to: 18749.99, value: 832.50, valueWisp: 0 },
  { from: 18750.00, to: 19249.99, value: 855.00, valueWisp: 0 },
  { from: 19250.00, to: 19749.99, value: 855.00, valueWisp: 0 },
  { from: 19750.00, to: 20249.99, value: 900.00, valueWisp: 0 },
  { from: 20250.00, to: 20749.99, value: 900.00, valueWisp: 22.50 },
  { from: 20750.00, to: 21249.99, value: 900.00, valueWisp: 45.00 },
  { from: 21250.00, to: 21749.99, value: 900.00, valueWisp: 67.50 },
  { from: 21750.00, to: 22249.99, value: 900.00, valueWisp: 90.00 },
  { from: 22250.00, to: 22749.99, value: 900.00, valueWisp: 112.50 },
  { from: 22750.00, to: 23249.99, value: 900.00, valueWisp: 135.00 },
  { from: 23250.00, to: 23749.99, value: 900.00, valueWisp: 157.50 },
  { from: 23750.00, to: 24249.99, value: 900.00, valueWisp: 180.00 },
  { from: 24250.00, to: 24749.99, value: 900.00, valueWisp: 202.50 },
  { from: 24750.00, to: 25249.99, value: 900.00, valueWisp: 225.00 },
  { from: 25250.00, to: 25749.99, value: 900.00, valueWisp: 247.50 },
  { from: 25750.00, to: 26249.99, value: 900.00, valueWisp: 270.00 },
  { from: 26250.00, to: 26749.99, value: 900.00, valueWisp: 292.50 },
  { from: 26750.00, to: 27249.99, value: 900.00, valueWisp: 315.00 },
  { from: 27250.00, to: 27749.99, value: 900.00, valueWisp: 337.50 },
  { from: 27750.00, to: 28249.99, value: 900.00, valueWisp: 360.00 },
  { from: 28250.00, to: 28749.99, value: 900.00, valueWisp: 382.50 },
  { from: 28750.00, to: 29249.99, value: 900.00, valueWisp: 405.00 },
  { from: 29250.00, to: 29749.99, value: 900.00, valueWisp: 427.50 },
  { from: 29750.00, to: 99999999999999, value: 900.00, valueWisp: 450.00 },
]

export default {
  props: ['computed'],
  name: 'ModalForm',
  data() {
    return {
      newComputed: this.computed,
      totalGrossPay: 0,
      totalHoliday: 0,
      totalTaxableGross: 0,
      withholdingTax: 0,
      sssTableContribution: SSS_TABLE,
      sssContribution: 0,
      phContribution: 0,
      pagIbigContribution: 0,
      netPay: 0,
      nightDiff: 0,
      totalOvertime: 0,
      thisSalary: 0,
    }
  }, 
  methods: {
    recompute: function(newVal) {
      let grossPay = newVal.grossPay;
      let allowance = newVal.allowance;
      let holiday = newVal.holiday;
      let overtime = newVal.overtime;

      this.computeTotalGrossPay(grossPay, allowance, holiday, overtime, newVal.nightDiff);
    },
    computeTotalGrossPay: function(grossPay, allowance, holiday, overtime, nightDiffCount) {
      let daily = this.computeDaily(grossPay);

      this.thisSalary = this.computeBasicSalary(grossPay);

      let regularHoliday = this.computeRegularHoliday(grossPay, holiday.regular);
      let nonWorkingHoliday = this.computeNonWorkingHoliday(grossPay, holiday.specialNonWorking);

      this.totalHoliday = (regularHoliday + nonWorkingHoliday);

      let regularOvertime = this.computeOvertime(daily, overtime.regular, 1.25);
      let regularHolidayOvertime = this.computeOvertime(daily * 2, overtime.regularHoliday, 1.30);
      let regularNonWorkingHolidayOvertime = this.computeOvertime(daily * 1.30, overtime.specialNonWorking, 1.30);

      this.totalOvertime = (regularOvertime + regularHolidayOvertime + regularNonWorkingHolidayOvertime);

      this.nightDiff = this.computeNightDiff(daily, nightDiffCount);

      this.totalGrossPay = (this.thisSalary + (allowance.nonTaxable + allowance.taxable) + (regularHoliday + nonWorkingHoliday) + (regularOvertime + regularHolidayOvertime + regularNonWorkingHolidayOvertime) + this.nightDiff);
      this.totalTaxableGross = (this.thisSalary + (allowance.taxable) + (regularHoliday + nonWorkingHoliday) + (regularOvertime + regularHolidayOvertime + regularNonWorkingHolidayOvertime) + this.nightDiff);

      this.withholdingTax = this.computeTax(this.totalTaxableGross, grossPay.frequency, grossPay);
      this.sssContribution = this.computeSSS(this.getMonthlyBasic(grossPay));
      this.phContribution = this.computePhilhealth(this.getMonthlyBasic(grossPay));
      this.pagIbigContribution = this.computePagIbig(this.getMonthlyBasic(grossPay));
      
      let totalDeductions = this.withholdingTax + this.sssContribution + this.phContribution + this.pagIbigContribution;

      this.netPay = (this.totalTaxableGross - totalDeductions) + allowance.nonTaxable;

      this.$refs["net-pay"].scrollIntoView({ behavior: "smooth" });
    },
    computeRegularHoliday(grossPay, totalDays) {
      let daily = this.computeDaily(grossPay);
      return daily * totalDays;
    },
    computeNonWorkingHoliday(grossPay, totalDays) {
      let daily = this.computeDaily(grossPay);
      return (daily * 0.30) * totalDays;
    },
    computeOvertime(daily, totalHours, multiplier) {
      let hourlyRate = this.computeHourly(daily);
      return (hourlyRate * multiplier) * totalHours;
    },
    getMonthlyBasic(grossPay) {
      if (grossPay.frequency == 'MONTHLY') {
        return grossPay.salary;
      } else if (grossPay.frequency == 'SEMI-MONTHLY') {
        return grossPay.salary * 2;
      } else {
        return grossPay.dailyRate * grossPay.days;
      }            
    },
    computeBasicSalary(grossPay) {
      if (grossPay.frequency == 'DAILY') {
        return grossPay.dailyRate * grossPay.days;
      } else {
        return grossPay.salary;
      }
    },    
    computeDaily: function(grossPay) {
      if (grossPay.frequency == 'MONTHLY') {
        return (grossPay.salary * 12) / (261);
      } else if (grossPay.frequency == 'SEMI-MONTHLY') {
        return ((grossPay.salary * 2) * 12) / (261);
      } else {
        return grossPay.dailyRate;
      }
    },
    computeHourly: function(daily) {
      return daily / 8;
    },
    computeNightDiff(daily, totalNightdiff) {
      return (this.computeHourly(daily) * 0.10) * totalNightdiff;
    },
    computeTax(taxableGross, frequency, grossPay) {
      if (frequency == 'MONTHLY') {
        return this.computeTaxMonthly(taxableGross);
      } else if (frequency == 'SEMI-MONTHLY')  {
        return this.computeTaxSemiMonthly(taxableGross);
      } else {
        return this.computeTaxDaily(grossPay.dailyRate, grossPay.days);
      }      
    },
    computeTaxMonthly(taxableGross) {
      if (taxableGross < 20833) {
        return 0;
      } else if (taxableGross >= 20834 && taxableGross <= 33332) {
        return ((taxableGross - 20833) * 0.15);
      } else if (taxableGross >= 33333 && taxableGross <= 66666) {
        return ((taxableGross - 33333) * 0.20) + 1875;
      } else if (taxableGross >= 66667 && taxableGross <= 166666) {
        return ((taxableGross - 66667) * 0.25) + 8541.80;
      } else if (taxableGross >= 166667 && taxableGross <= 666666) {
        return ((taxableGross - 166667) * 0.30) + 33541.80;
      } else if (taxableGross >= 666667) {
        return ((taxableGross - 666666) * 0.35) + 183541.80;
      }      
    },
    computeTaxSemiMonthly(taxableGross) {
      if (taxableGross < 10417) {
        return 0;
      } else if (taxableGross >= 10417 && taxableGross <= 16666) {
        return ((taxableGross - 10417) * 0.15);
      } else if (taxableGross >= 16667 && taxableGross <= 33332) {
        return ((taxableGross - 16667) * 0.20) + 937.50;
      } else if (taxableGross >= 33333 && taxableGross <= 83332) {
        return ((taxableGross - 33333) * 0.25) + 4270.70;
      } else if (taxableGross >= 83333 && taxableGross <= 333332) {
        return ((taxableGross - 83332) * 0.30) + 16770.70;
      } else if (taxableGross >= 333333) {
        return ((taxableGross - 333333) * 0.35) + 91770.70;
      }
    },
    computeTaxDaily(dailyRate, days) {
      if (dailyRate < 685) {
        return 0;
      } else if (dailyRate >= 685 && dailyRate <= 1095) {
        return ((dailyRate - 685) * 0.15) * days;
      } else if (dailyRate >= 1096 && dailyRate <= 2912) {
        return (((dailyRate - 1096) * 0.20) + 61.65) * days;
      } else if (dailyRate >= 2192 && dailyRate <= 5478) {
        return (((dailyRate - 2192) * 0.25) + 280.85) * days;
      } else if (dailyRate >= 5479 && dailyRate <= 21917) {
        return (((dailyRate - 5479) * 0.30) + 1102.60) * days;
      } else if (dailyRate >= 21918) {
        return (((dailyRate - 21918) * 0.35) + 6034.30) * days;
      }
    },  
    computeSSS(salary) {

      let contribution = 0;

      this.sssTableContribution.some(sss => {
        if (salary >= sss.from && salary <= sss.to) {
          contribution = (sss.value + sss.valueWisp);
          return true;
        }
        return false;
      });

      return contribution;
    },
    computePhilhealth(salary) {
      if (salary == 10000) {
        return 400;
      } else if (salary > 10000 && salary < 80000) {
        return salary * 0.04;
      } else if (salary >= 80000) {
        return 3200;
      } else {
        return 0;
      }
    },
    computePagIbig(salary) {
      if (salary <= 1500) {
        return (salary * 0.01);
      } else if (salary >= 1501 && salary <= 4999.99) {
        return (salary * 0.02)
      } else {
        return (5000 * 0.03)
      }
    },
    toCurrency(balance) {
          return (Math.round(balance * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    reset() {
      this.totalGrossPay = 0;
      this.totalTaxableGross = 0;
      this.withholdingTax = 0;
      this.sssContribution =  0;
      this.phContribution = 0;
      this.pagIbigContribution = 0;
      this.netPay = 0;
      this.nightDiff = 0;
      this.totalOvertime = 0;
      this.thisSalary = 0;
    }                  
  },
  watch: {
    computed: {
      immidiate: true,
      handler(newVal) {
        if (newVal != null) {
          this.reset();
          this.recompute(newVal);
        }
      }
    }
  }
}
</script>

<style>

.table {
  text-align: left;
}

.table > tr > td {
  min-width: 100px;
}


.my-table > tr > td:first-child { text-align: right; }

.table-header > td {
  text-align: center !important;
  background-color: #e0e0e0;
  color: #363636;
  font-size: 1rem;
  font-weight: 600;
}

.net-pay {
  background-color: whitesmoke !important;
  font-size: 2rem !important;
}
</style>
