<template>
  <form class="box">

    <div class="field">
      <label class="label">Gross Pay</label>
      <div class="control">
        <div :class="'select ' + getValidationClass(validation.grossPay.frequency)">
          <select class="select" v-model="value.grossPay.frequency" @change="frequencyOnChange($event)">
            <option value="MONTHLY">Monthly</option>
            <option value="SEMI-MONTHLY">Semi-Monthly</option>
            <option value="DAILY">Daily</option>
          </select>
        </div>        
      </div>      
    </div>
    
    <div class="field" v-if="showDailyAttributes == false">
      <div class="control has-icons-right">
        <input :class="'input ' + getValidationClass(validation.grossPay.salary)" type="number" v-model="value.grossPay.salary" placeholder="Your Basic Salary">
      </div>
    </div>

    <div class="field" v-if="showDailyAttributes == true">
      <div class="control">
        <input :class="'input ' + getValidationClass(validation.grossPay.dailyRate)" type="number" v-model="value.grossPay.dailyRate" placeholder="Daily Rate">
      </div>
    </div>

    <div class="field">
      <div class="control" v-if="showDailyAttributes == true">
        <input :class="'input ' + getValidationClass(validation.grossPay.days)" type="number" v-model="value.grossPay.days" placeholder="Num of days">
      </div>
    </div>    

    <div class="field">
      <div class="control">
          <vue-collapsible-panel-group base-color="#363636" accordion>
            <vue-collapsible-panel @click="expand()" :expanded="expandAddPayments">
                <template #title>
                    Additional Payments
                </template>
                <template #content>
                    <div class="field">
                      <label class="label">Allowances</label>
                      <div class="control">
                        <input :class="'input ' + getValidationClass(validation.allowance.nonTaxable)" type="number" placeholder="Non-Taxable Allowance" v-model="value.allowance.nonTaxable">
                      </div>
                    </div>

                    <div class="field">
                      <div class="control">
                        <input :class="'input ' + getValidationClass(validation.allowance.taxable)" type="number" placeholder="Taxable Allowance" v-model="value.allowance.taxable">
                      </div>
                    </div>

                    <div class="field">
                      <label class="label">Holiday (Total days)</label>
                      <div class="control">
                        <input :class="'input ' + getValidationClass(validation.holiday.regular)" type="number" placeholder="Regular Holiday" v-model="value.holiday.regular">
                      </div>
                    </div>
                    
                    <div class="field">
                      <div class="control">
                        <input :class="'input ' + getValidationClass(validation.holiday.specialNonWorking)" type="number" placeholder="Special Non-working Holiday" v-model="value.holiday.specialNonWorking">
                      </div>
                    </div>     

                    <div class="field">
                      <label class="label">Overtime (Total hours)</label>
                      <div class="control">
                        <input :class="'input ' + getValidationClass(validation.overtime.regular)" type="number" placeholder="Regular OT" v-model="value.overtime.regular">
                      </div>
                    </div>  

                    <div class="field">
                      <div class="control">
                        <input :class="'input ' + getValidationClass(validation.overtime.regularHoliday)" type="number" placeholder="Regular holiday OT" v-model="value.overtime.regularHoliday">
                      </div>
                    </div>  

                    <div class="field">
                      <div class="control">
                        <input :class="'input ' + getValidationClass(validation.overtime.specialNonWorking)" type="number" placeholder="Special Non-working Holiday OT" v-model="value.overtime.specialNonWorking">
                      </div>
                    </div>  

                    <div class="field">
                      <label class="label">Night-Diff</label>
                      <div class="control">
                        <input :class="'input ' + getValidationClass(validation.nightDiff)" type="number" placeholder="Total Hours" v-model="value.nightDiff">
                      </div>
                    </div>
                </template>
            </vue-collapsible-panel>
          </vue-collapsible-panel-group>   
      </div>
    </div>     

    <div class="field">
      <div class="control">
        <button type="button" class="button is-dark compute-btn" @click="openModal()">Compute</button>
      </div>
    </div>  
  </form>
  

</template>

<script>

import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import _ from 'lodash'

export default {
  props: ["mainComputed", "onComputed"],
  name: 'MainForm',
  data() {
    return {
      isModalActive: false,
      expandAddPayments: false,
      grossPay: {
        frequency: null,
        salary: null
      },
      value: {
        grossPay: {
          frequency: null,
          salary: null,
          dailyRate: null,
          days: null,
        },
        allowance: {
          nonTaxable: null,
          taxable: null,
        },
        holiday: {
          regular: null,
          specialNonWorking: null
        },
        overtime: {
          regular: null,
          regularHoliday: null,
          specialNonWorking: null
        },
        nightDiff: null        
      },
      showDailyAttributes: false,
      computed: this.mainComputed,
      validation: {
        grossPay: {
          frequency: true,
          salary: true,
          dailyRate: true,
          days: true,
        },
        allowance: {
          nonTaxable: true,
          taxable: true,
        },
        holiday: {
          regular: true,
          specialNonWorking: true
        },
        overtime: {
          regular: true,
          regularHoliday: true,
          specialNonWorking: true
        }        
      }
    }
  },
  methods: {
    clickMe() {
        alert(this.frequency);
    },
    frequencyOnChange(event) {
      if (event.target.value === "DAILY") {
        this.showDailyAttributes = true;
      } else {
        this.showDailyAttributes = false;
      }
    },
    expand() {
      return this.expandAddPayments = !this.expandAddPayments;
    },
    closeModal() {
      this.isModalActive = false;
    },
    validateForm() {
      let hit = true;

      if (!_.isNil(this.value.grossPay.frequency)) {
        this.validation.grossPay.frequency = true;

        if (this.value.grossPay.frequency === "MONTHLY" 
          || this.value.grossPay.frequency === "SEMI-MONTHLY") {
            if (!_.isNil(this.value.grossPay.salary)) {
              this.validation.grossPay.salary = true;
            } else {
              this.validation.grossPay.salary = false;
              hit = false;
            }
        } else {

          if (!_.isNil(this.value.grossPay.dailyRate) || this.value.grossPay.dailyRate >= 1) {
            this.validation.grossPay.dailyRate = true;
          } else {
            this.validation.grossPay.dailyRate = false;
            hit = false;
          }

          if (!_.isNil(this.value.grossPay.days) || this.value.grossPay.days >= 1) {
              this.validation.grossPay.days = true;
          } else {
            this.validation.grossPay.days = false;
            hit = false;
          }          
        }

      } else {
        this.validation.grossPay.frequency = false;
        hit = false;
      }

      if (this.value.allowance.nonTaxable >= 0) {
          this.validation.allowance.nonTaxable = true;
      } else {
        this.validation.allowance.nonTaxable = false;
        hit = false;
      }

      if (this.value.allowance.taxable >= 0) {
          this.validation.allowance.taxable = true;
      } else {
        this.validation.allowance.taxable = false;
        hit = false;
      }
      
      if (this.value.holiday.regular >= 0) {
          this.validation.holiday.regular = true;
      } else {
        this.validation.holiday.regular = false;
        hit = false;
      }    

      if (this.value.holiday.specialNonWorking >= 0) {
          this.validation.holiday.specialNonWorking= true;
      } else {
        this.validation.holiday.specialNonWorking = false;
        hit = false;
      }     

      if (this.value.overtime.regular >= 0) {
          this.validation.overtime.regular= true;
      } else {
        this.validation.overtime.regular= false;
        hit = false;
      }     

      if (this.value.overtime.regularHoliday >= 0) {
          this.validation.overtime.regularHoliday= true;
      } else {
        this.validation.overtime.regularHoliday= false;
        hit = false;
      }  

      if (this.value.overtime.specialNonWorking >= 0) {
          this.validation.overtime.specialNonWorking= true;
      } else {
        this.validation.overtime.specialNonWorking= false;
        hit = false;
      }    
      
      if (this.value.overtime.specialNonWorking >= 0) {
          this.validation.overtime.specialNonWorking= true;
      } else {
        this.validation.overtime.specialNonWorking= false;
        hit = false;
      }

      if (this.value.nightDiff >= 0) {
          this.validation.nightDiff = true;
      } else {
        this.validation.nightDiff = false;
        hit = false;
      }      

      return hit;
    },
    openModal() {
      if (this.validateForm() == true) {
        this.onComputed(_.cloneDeep(this.value))
      }
    },
    getValidationClass(field) {
      return field == true ? 'is-dark' : 'is-danger'
    },
    resetValues() {
      this.value = {
        grossPay: {
          frequency: null,
          salary: null,
          dailyRate: null,
          days: null,
        },
        allowance: {
          nonTaxable: null,
          taxable: null,
        },
        holiday: {
          regular: null,
          specialNonWorking: null
        },
        overtime: {
          regular: null,
          regularHoliday: null,
          specialNonWorking: null
        },
        nightDiff: null        
      }     
    }
  }
}
</script>

<style>
.select {
  width: 100%;
}

.compute-btn {
  width: 100%;
}

.label {
  text-align: left;
}

.box {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
