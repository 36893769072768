<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">

    </div>
  </nav>  



  <div class="main-cont columns is-desktop">
    <div class="column is-two-fifths">
      <MainForm :mainComputed="computed" :onComputed="onClick"/>
    </div>
    <div class="column">
      <ModalForm :computed="computed"/>
    </div>
  </div>

  <footer class="footer">
    <div class="content has-text-centered">
    </div>
  </footer>  
    
</template>

<script>

import MainForm from '../components/forms/MainForm.vue';
import ModalForm from '../components/forms/ModalForm.vue';

export default {
  name: 'MainPage',
  props: {
    msg: String
  },
  data() {
    return {
      computed: null,
      modalComputed: null
    }
  },
  components: {
    MainForm,
    ModalForm
  },
  methods: {
    onClick: function(computed){
      this.computed = computed;
    }
  } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.main-cont {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px !important;
  margin-bottom: 20px;
}

.navbar, footer {
  background-color: #363636 !important;
}

</style>
