import { createApp } from 'vue'
import App from './App.vue'
import 'buefy/dist/buefy.css'
import VueCollapsiblePanel from '@dafcoe/vue-collapsible-panel'

const app = createApp(App);
app.use(VueCollapsiblePanel).mount('#app');

app.config.globalProperties.$filters = {
    currencyUSD(value) {
      return '$' + value
    }
}

